import { FC, memo } from 'react';
import { Body, Button, Caption, Close, Flex, Heading, Modal, PairMembership } from '@components';
import { useTranslation } from '@utils/index';
import styles from './VipPromoModal.module.scss';

interface VipPromoModalProps {
	children: React.ReactNode;
}

const VipPromoModal: FC<VipPromoModalProps> = ({ children }) => {
	const { translator: t } = useTranslation();
	return (
		<Modal>
			<Modal.Trigger asChild>{children}</Modal.Trigger>
			<Modal.Content className={styles.modal} center includeCloseButton={false}>
				<Modal.Close className={styles.close}>
					<Close label='Close' noHover width={13} height={13} />
				</Modal.Close>
				<Flex column justify='between' className={styles.container}>
					<div className={styles.header}>
						<PairMembership height={60} width={100} />
						<Caption className={styles.title}>{t('vip-club-membership')}</Caption>
					</div>
					<div className={styles.content}>
						<Heading tag='h6' className={styles.subtitle}>
							{t('how-it-works')?.toUpperCase()}
						</Heading>
						<Body>{t('vip-promo-description')}</Body>
						<ul className={styles.benefits}>
							<li>{t('vip-benefit-1')}</li>
							<li>{t('vip-benefit-2')}</li>
							<li>{t('vip-benefit-3')}</li>
						</ul>
						<Body>{t('vip-sign-up')}</Body>
					</div>
					<Modal.Close asChild>
						<Button color='green' extraClasses={styles.button}>
							{t('continue-shopping')}
						</Button>
					</Modal.Close>
				</Flex>
			</Modal.Content>
		</Modal>
	);
};

export default memo(VipPromoModal);
