// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, ForwardedRef, forwardRef } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { COLORS_SWAP_MAP, LOCALE_CODES, LOCALE_DICT, REFLECTIVE_ADDON_PRICE } from '@constants';
import { Caption, Dash, Flex, Label, LabelText, Paragraph } from '@components';
import { formatCurrency } from '@utils/shopify';
import { useWindowDimensions } from '@utils/hooks';
import { frameSwatchesProps } from '@ts/components';
import Swatch from '../Swatch/Swatch';
import styles from './SwatchController.module.scss';

export type SwatchOption = { name: string; tag?: { label: string; type: 'success' | 'error' } };

interface SwatchControllerProps {
	activateOnHover?: boolean;
	alignment?: 'left' | 'center' | 'right';
	callback: ({ option, type }: { option: string; type: 'frame' | 'lens' | 'option' }) => void;
	extraClasses?: string;
	name: string;
	options: readonly string[] | Array<SwatchOption>;
	ref?: ForwardedRef<HTMLDivElement>;
	selected: string;
	title?: string;
	label?: string;
	showDescription?: boolean;
	excludeColorLabel?: boolean;
	parent?: 'sidebar' | 'product-card';
	type: 'frame' | 'lens' | 'option' | 'accessory';
	frameSwatches?: frameSwatchesProps;
	showNewSticky?: boolean;
}

const SwatchController: FC<SwatchControllerProps> = forwardRef(
	(
		{
			activateOnHover,
			alignment,
			callback,
			extraClasses,
			name,
			options,
			selected,
			showDescription = false,
			excludeColorLabel = false,
			title,
			parent = 'sidebar',
			type,
			frameSwatches,
			label = null,
			showNewSticky = false,
			...rest
		},
		ref
	) => {
		const { locale } = useRouter();

		const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
		const reflectivePricing =
			type === 'lens' &&
			selected?.toLowerCase().includes('reflective') &&
			`+${formatCurrency(
				{ amount: REFLECTIVE_ADDON_PRICE, currencyCode: LOCALE_DICT[locale].currencyCode, locale },
				showCurr
			)}`;
		const containerClasses = cn(styles['container'], extraClasses, {
			[styles['container--center']]: alignment === 'center',
			[styles['container--left']]: alignment === 'left',
			[styles['container--right']]: alignment === 'right',
		});

		const baseFrameColorLabel = COLORS_SWAP_MAP.Acetate[selected] ?? selected;
		const { width } = useWindowDimensions();
		const isDesktopView = width > 991;

		return (
			<div className={containerClasses} ref={ref} {...rest}>
				{parent === 'sidebar' && (
					<Flex center style={{ marginBottom: '0.8rem' }}>
						{!label && <Label>{title}</Label>}
						{showNewSticky && type === 'option' && (
							<>
								{label && <LabelText>{label}</LabelText>}
								<Dash />
								<Paragraph>{title}</Paragraph>
							</>
						)}
						{type !== 'option' && (
							<>
								{label && <LabelText>{label}</LabelText>}
								{!excludeColorLabel && (
									<>
										<Dash />
										<Paragraph>
											{baseFrameColorLabel} {reflectivePricing}
										</Paragraph>
									</>
								)}
							</>
						)}
					</Flex>
				)}

				{parent === 'product-card' && (
					<Flex center style={{ marginBottom: title && '0.8rem', justifyContent: 'center' }}>
						{title && (
							<Paragraph>
								{`${title} ${selected}`} {type === 'lens' && 'Lenses'} {reflectivePricing}
							</Paragraph>
						)}
					</Flex>
				)}

				<Flex className={styles.swatchContainer}>
					<ul
						className={styles['list']}
						style={
							type === 'frame' || type === 'accessory' || (type === 'option' && isDesktopView)
								? { flexWrap: 'wrap' }
								: { flexWrap: 'none' }
						}
					>
						{options.map((option, index) => {
							const label = typeof option === 'string' ? option : option.name;

							return (
								<Swatch
									key={`swatch-${label}`}
									activateOnHover={activateOnHover}
									option={label}
									callback={callback}
									index={index}
									name={name}
									selected={selected}
									type={type}
									frameSwatches={frameSwatches}
									tag={typeof option === 'string' ? null : option.tag}
								/>
							);
						})}
					</ul>
					{showDescription && <Caption className={styles.description}>{`in ${selected}`}</Caption>}
				</Flex>
			</div>
		);
	}
);

SwatchController.displayName = 'SwatchController';

export default SwatchController;
