/* eslint-disable max-lines */
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import cn from 'classnames';
import { A11y, Navigation, Pagination, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';
import { getDefaultSwiper, MODEL_LIFESTYLE_IMAGE } from '@constants';
import {
	Button,
	CarouselTags as Tags,
	Flex,
	Loading,
	Modal,
	NavButtons,
	SaveTag,
	Slide,
	Thumbnails,
	VideoIcon,
	VideoSlide,
} from '@components';
import { CarouselProps } from '@ts/components';
import { generateTopFrameImages } from '@utils/images';
import { useIsMobile, useTranslation } from '@utils/hooks';
import styles from './Carousel.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

const BASE_SWIPER_PROPS = {
	pagination: {
		clickable: true,
		type: 'bullets',
		bulletElement: 'button',
		el: '.swiper-pagination',
		bulletClass: styles['swiper-pagination-bullet'],
		bulletActiveClass: styles['swiper-pagination-bullet-active'],
		lockClass: styles['swiper-pagination-lock'],
		clickableClass: styles['swiper-pagination-clickable'],
	} as PaginationOptions,
};

export const DEFAULT_SWIPER_PROPS = {
	...getDefaultSwiper(),
	...BASE_SWIPER_PROPS,
};

const getLocalizedDefaultSwiperProps = (locale: string) => {
	const DEFAULT_SWIPER = getDefaultSwiper(locale);
	return {
		...DEFAULT_SWIPER,
		...BASE_SWIPER_PROPS,
	};
};

const getTranslatedTexts = translator => {
	return {
		virtualTryOn: translator('virtual-try-on'),
	};
};

const Carousel = ({
	className,
	hideThumbnail = false,
	images,
	imageLoadingHandler,
	isModalOpen,
	isImageLoading = false,
	isSecondaryImageLoading = false,
	mode = 'pdp',
	modalType = 'Imagery',
	name,
	product,
	secondary,
	selectedImgIndex,
	setSelectedImgIndex,
	setModalType,
	swiperOptions,
	tags,
	type,
	video,
	variant,
	VTODispatch,
	topFrameOnModal,
	isSticky = true,
}: CarouselProps) => {
	const isImageGallery = useFeatureIsOn('is-image-gallery');
	const isMobile = useIsMobile();
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const defaultSwipperPropsTranslated = getLocalizedDefaultSwiperProps(locale);
	const swiperOptionsDefault = swiperOptions || defaultSwipperPropsTranslated;

	const refs = {
		nextEl: useRef(null),
		prevEl: useRef(null),
		slider: useRef(null),
		loading: useRef(null),
		modalSlider: useRef(null),
		modalNextEl: useRef(null),
		modalPrevEl: useRef(null),
	};

	const isTopFrameBundle = type.includes('BUNDLE');
	const customPagination = {
		...defaultSwipperPropsTranslated,
		pagination: {
			...defaultSwipperPropsTranslated.pagination,
			bulletClass: video ? styles['swiper-pagination-bullet-video'] : styles['swiper-pagination-bullet'],
			bulletActiveClass: video
				? styles['swiper-pagination-bullet-active-video']
				: styles['swiper-pagination-bullet-active'],
		},
	};
	const { topFrameVariant, copyTopFrameVariant } = generateTopFrameImages(secondary, isImageLoading, images, mode, type);

	const [isThumbsClicked, setIsThumbsClicked] = useState(false);
	const withThreeFourthImage = copyTopFrameVariant?.length === 5;
	const topFrameVariantWithLifestyle = [
		...copyTopFrameVariant,
		...(!isImageLoading && topFrameOnModal?.length > 0
			? [{ primary: topFrameOnModal[0]?.url, secondary: MODEL_LIFESTYLE_IMAGE }]
			: []),
	];

	const currentSliderRef = isModalOpen ? refs.modalSlider : refs.slider;
	const handleNavigation = useCallback(
		action => {
			if (!currentSliderRef.current) return;
			const swiperInstance = currentSliderRef.current.swiper;
			switch (action) {
				case 'prev':
					swiperInstance.slidePrev();
					break;
				case 'next':
					swiperInstance.slideNext();
					break;
				default:
					break;
			}
			isThumbsClicked && setIsThumbsClicked(!isThumbsClicked);
		},
		[isModalOpen, isThumbsClicked, setIsThumbsClicked]
	);

	useEffect(() => {
		const handleKeyDown = ({ key }) => {
			if (key === 'ArrowRight') handleNavigation('next');
			if (key === 'ArrowLeft') handleNavigation('prev');
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleNavigation]);

	const useSlideToLoop = sliderRef => {
		useEffect(() => {
			if (sliderRef.current && isThumbsClicked) {
				sliderRef.current.swiper?.slideToLoop(selectedImgIndex);
			}
		}, [isThumbsClicked, selectedImgIndex, sliderRef]);
	};

	useSlideToLoop(refs.slider);

	const handleSlideChange = newIndex => {
		if (!isNaN(newIndex)) {
			setSelectedImgIndex(newIndex);
		} else {
			refs.slider.current.swiper.slideTo(selectedImgIndex);
		}
	};

	const isBFMode = mode === 'buildflow';

	const videoSlide = videoIdx => {
		return (
			video && (
				<SwiperSlide
					tag='li'
					zoom={swiperOptionsDefault.zoom}
					key={`video-slide-${video.id || 'default'}`}
					className={styles['swiper-slide']}
					data-swiper-slide-index={videoIdx}
				>
					{isImageGallery ? (
						<Modal.Trigger asChild onClick={() => setModalType('Imagery')}>
							<Flex fullHeight>
								<VideoSlide video={video} type={type} name={name} />
							</Flex>
						</Modal.Trigger>
					) : (
						<VideoSlide video={video} type={type} name={name} />
					)}
				</SwiperSlide>
			)
		);
	};

	const mappedTopFrameSlides = useCallback(() => {
		const classes = cn(styles['swiper-slide'], {
			[styles['with-3-4-image']]: withThreeFourthImage && !isBFMode,
			[styles['without-3-4-image']]: !withThreeFourthImage && !isBFMode,
			[styles['buildflow-images--with-3-imgs']]: isBFMode && topFrameVariant?.length < 2,
			[styles['buildflow-images--with-5-imgs']]: isBFMode && topFrameVariant?.length >= 2,
		});
		const slides =
			topFrameVariantWithLifestyle?.map(({ primary, secondary }, index) => {
				const topFrameSlideContent = (
					<Slide
						index={index}
						imageLoadingHandler={imageLoadingHandler}
						isModalOpen={isModalOpen}
						name={name}
						primary={primary}
						secondary={secondary}
						type={primary ? type : 'LIFESTYLE'}
						key={`image-slide-${index}`}
					/>
				);
				return (
					<SwiperSlide
						tag='li'
						zoom={swiperOptionsDefault.zoom}
						key={`image-slide-${index}`}
						className={classes}
						data-swiper-slide-index={index}
					>
						{isImageGallery ? (
							<Modal.Trigger asChild onClick={() => setModalType('Imagery')}>
								<Flex fullHeight>{topFrameSlideContent}</Flex>
							</Modal.Trigger>
						) : (
							topFrameSlideContent
						)}
					</SwiperSlide>
				);
			}) || [];
		const videoSlideIndex = topFrameVariantWithLifestyle?.length || 0;
		return slides.concat(videoSlide(videoSlideIndex));
	}, [
		topFrameVariantWithLifestyle,
		imageLoadingHandler,
		isBFMode,
		name,
		swiperOptionsDefault,
		topFrameVariant,
		type,
		withThreeFourthImage,
		setModalType,
	]);

	const mappedSwiperSlides = useCallback(() => {
		let fixedImages = [...images];

		if (isBFMode) {
			fixedImages = [...images, images[0], images[1], images[0]];
		}

		const slides =
			fixedImages?.map(({ url, width = 1200, height = 600, altText }, index) => {
				const slideContent = (
					<Slide
						index={index}
						type={altText?.includes('lifestyle') ? 'LIFESTYLE' : type}
						name={name}
						imageLoadingHandler={imageLoadingHandler}
						url={url}
						height={height}
						width={width}
						key={`image-slide-${index}`}
					/>
				);
				return (
					<SwiperSlide
						tag='li'
						zoom={swiperOptionsDefault.zoom}
						key={`image-slide-${index}`}
						className={cn(styles['swiper-slide'], {
							[styles['buildflow-images--with-5-imgs']]: isBFMode,
						})}
						data-swiper-slide-index={index}
					>
						{isImageGallery ? (
							<Modal.Trigger asChild onClick={() => setModalType('Imagery')}>
								<Flex fullHeight>{slideContent}</Flex>
							</Modal.Trigger>
						) : (
							slideContent
						)}
					</SwiperSlide>
				);
			}) || [];
		const videoSlideIndex = fixedImages?.length || 0;
		return slides.concat(videoSlide(videoSlideIndex));
	}, [
		imageLoadingHandler,
		images,
		isBFMode,
		name,
		secondary,
		swiperOptionsDefault,
		topFrameVariant,
		type,
		withThreeFourthImage,
		setModalType,
	]);

	const navigationRefs = {
		prevEl: isModalOpen ? refs.modalPrevEl.current : refs.prevEl.current,
		nextEl: isModalOpen ? refs.modalNextEl.current : refs.nextEl.current,
	};

	const swiperContent = () => (
		<Swiper
			ref={isModalOpen ? refs.modalSlider : refs.slider}
			autoHeight={true}
			className={styles['container']}
			modules={[A11y, Navigation, Pagination, Zoom]}
			navigation={navigationRefs}
			a11y={swiperOptionsDefault.a11y}
			pagination={customPagination.pagination}
			data-type={type}
			{...customPagination}
			onSlideChange={swiper => {
				if (!isModalOpen) {
					handleSlideChange(swiper.realIndex);
				}
			}}
			initialSlide={selectedImgIndex}
			loop={isImageGallery ? false : true}
		>
			{secondary ? mappedTopFrameSlides() : mappedSwiperSlides()}
			{isImageLoading || (isSecondaryImageLoading && <Loading ref={refs.loading} className={styles['image-loader']} />)}
			<div className={cn('swiper-pagination', styles['swiper-pagination'])} />
			{!isTopFrameBundle && (
				<NavButtons
					handleNavigation={handleNavigation}
					images={images}
					type={type}
					prevElRef={navigationRefs.prevEl}
					nextElRef={navigationRefs.nextEl}
				/>
			)}
			{variant?.metafields?.discountTag && (
				<div className={styles.saveTag}>
					<SaveTag label='logo-pair-eyewear-color' detail={variant.metafields?.discountTag} />
				</div>
			)}
			<Tags tags={variant?.tags && variant?.tags.length > 0 ? variant.tags : tags} />
			{VTODispatch && (
				<Modal.Trigger asChild>
					<Flex justify='center'>
						<Button
							color='white'
							extraClasses={styles.vtoButton}
							data-tryon={name}
							onClick={() =>
								VTODispatch({
									type: 'product',
									option: {
										product,
										variantIdentifier: variant?.option,
									},
								})
							}
						>
							<VideoIcon /> {translations.virtualTryOn}
						</Button>
					</Flex>
				</Modal.Trigger>
			)}
		</Swiper>
	);

	return (
		<Flex
			maxWidth
			className={cn(styles['carousel-container'], className, { [styles['carousel-container--static']]: !isSticky })}
		>
			<Thumbnails
				images={images}
				selectedImgIndex={selectedImgIndex}
				setSelectedImgIndex={setSelectedImgIndex}
				name={name}
				type={type}
				withThreeFourthImage={withThreeFourthImage}
				copyTopFrameVariant={topFrameVariantWithLifestyle}
				hideThumbnail={hideThumbnail}
				imageLoadingHandler={imageLoadingHandler}
				isThumbsClicked={isThumbsClicked}
				secondary={secondary}
				setIsThumbsClicked={setIsThumbsClicked}
				video={video}
			/>
			{swiperContent()}
			{isImageGallery && modalType === 'Imagery' && (
				<>
					<Modal.Content className={styles.modalContent} removePadding includeCloseButton={isMobile}>
						{swiperContent()}
					</Modal.Content>
				</>
			)}
		</Flex>
	);
};

export default memo(Carousel);
