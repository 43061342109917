import React, { CSSProperties, ForwardedRef, forwardRef, useContext, useState } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { AnimatePresence, m, MotionProps } from 'framer-motion';
import cn from 'classnames';
import { Close, ModalContext } from '@components';
import { fade, slideFromBottom, slideUp } from '@utils/motions';
import { useIsMobile, useTranslation } from '@utils/hooks';
import styles from './Modal.module.scss';

interface ModalProps extends Partial<DialogPrimitive.DialogContentProps> {
	className?: string;
	open?: boolean;
	ref?: ForwardedRef<unknown>;
	removePadding?: boolean;
	hideOverflow?: boolean;
	includeCloseButton?: boolean;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	customAnimation?: MotionProps;
	/** Modal will animate to the center of the viewport, regardless of device size **/
	center?: boolean;
	small?: boolean;
	closeButtonSticky?: boolean;
}

const getTranslatedTexts = translator => {
	return {
		close: translator('close'),
	};
};

const ModalContent = forwardRef<HTMLDivElement, ModalProps>(
	(
		{
			children,
			className,
			includeCloseButton = true,
			removePadding = false,
			hideOverflow = false,
			customAnimation,
			center = false,
			small = false,
			closeButtonSticky = false,
			...props
		},
		forwardedRef
	) => {
		const isMobile = useIsMobile();
		const animation = isMobile && !center ? slideFromBottom : slideUp;
		const classes = cn(className, {
			[styles.content]: !removePadding,
			[styles.contentWithoutPadding]: removePadding,
			[styles.hideOverflow]: hideOverflow,
			[styles.contentCenter]: center,
			[styles.small]: small,
		});
		const { translator } = useTranslation();
		const translations = getTranslatedTexts(translator);

		const open = useContext(ModalContext);
		const defaultStyle = { zIndex: 1000, overflowY: 'scroll' } as Partial<CSSProperties>;

		return (
			<AnimatePresence>
				{open ? (
					<DialogPrimitive.Portal forceMount>
						<DialogPrimitive.Overlay asChild forceMount className={styles['overlay']}>
							<m.div data-test-overlay {...fade}></m.div>
						</DialogPrimitive.Overlay>

						<DialogPrimitive.Content
							{...props}
							asChild
							forceMount
							className={classes}
							ref={forwardedRef}
							style={!!props.style ? { ...props.style, ...defaultStyle } : defaultStyle}
						>
							<m.div {...(customAnimation ?? animation)}>
								<div className={cn({ [styles['close-container']]: closeButtonSticky })}>
									{includeCloseButton && (
										<DialogPrimitive.Close aria-label={translations.close} className={styles['close']}>
											<Close label={translations.close} />
										</DialogPrimitive.Close>
									)}
								</div>
								{children}
							</m.div>
						</DialogPrimitive.Content>
					</DialogPrimitive.Portal>
				) : null}
			</AnimatePresence>
		);
	}
);

const Modal = ({ children, open: customOpen = null, onOpenChange = null }) => {
	const [open, setOpen] = useState(false);

	return (
		<DialogPrimitive.Root onOpenChange={o => (!!onOpenChange ? onOpenChange(o) : setOpen(o))}>
			<ModalContext.Provider value={!!onOpenChange ? customOpen : open}>{children}</ModalContext.Provider>
		</DialogPrimitive.Root>
	);
};

ModalContent.displayName = 'ModalContent';
Modal.Content = ModalContent;
Modal.Trigger = DialogPrimitive.Trigger;
Modal.Close = DialogPrimitive.Close;

export default Modal;
