import { LOCALE_CODES, LOCALE_DICT, PRODUCT_TYPES } from '@constants';
import { CurrencyCode } from '@ts/shopify-storefront-api';
import { translate } from '@utils/locales';

type list = {
	description: string;
	checkmark: boolean;
	tooltip?: string;
}[];

export function getPurchaseChecklist(locale: string, threshold: number): list {
	const currency = LOCALE_DICT[locale].currency;
	const countryCode = LOCALE_DICT[locale].countryCode;
	const checklist = [
		{
			description: !!threshold
				? translate('free-shipping-on-plus-orders', locale, { currency, threshold })
				: translate('free-shipping-on-code-orders', locale, { countryCode }),
			checkmark: true,
		},
		{
			description: translate('trial-period', locale),
			checkmark: true,
		},
		{
			description: translate('fsa-hsa-eligible', locale),
			checkmark: true,
			tooltip: translate('original-payment-refund', locale),
		},
	];

	if (locale === LOCALE_CODES.GB || locale === LOCALE_CODES.AU) {
		checklist.pop();
	}

	return checklist;
}

export const emptyUpsellProduct = {
	availableForSale: true,
	totalInventory: 1,
	id: null,
	path: null,
	slug: null,
	price: { amount: 0, currencyCode: CurrencyCode.Usd },
	type: PRODUCT_TYPES.LENS,
	images: [],
	variants: [],
	options: [],
	tags: [],
};

export const emptyUpsellLine = {
	id: null,
	variant: null,
	properties: null,
	discountAllocations: null,
	quantity: 1,
};
