import React from 'react';
import { useRouter } from 'next/router';
import { slideUp } from '@utils/motions';
import { LOCALE_DICT } from '@constants';
import { useSiteSettings } from '@services/contentful';
import { Caption, Close, getThreshold, Paragraph, Title } from '@components';
import { useLocaleContext } from '@context';
import { formatCurrency, LOCALE_CODES, useTranslation } from '@utils/index';
import Modal from '../Modal';
import Flex from '../Flex';
import Button from '../Button';
import styles from './InternationalWelcomeModal.module.scss';

const getTranslatedTexts = translator => {
	return {
		welcomeToPairEyewear: translator('welcome-to-pair-eyewear'),
		youShopIn: (localeCopy: string) => translator('you-shop-in', { localeCopy }),
		shopInLocal: translator('shop-in-local'),
		allDutiesAndTaxes: translator('all-duties-and-taxes'),
		freeShippingOnOver: (formattedThreshold: string) => translator('free-shipping-on-over', { formattedThreshold }),
		close: translator('close-uppercase'),
		continueShopping: translator('continue-shopping-title-case'),
	};
};

const InternationalWelcomeModal = () => {
	const { locale } = useRouter();
	const { data: siteSettings } = useSiteSettings();
	const { openModal, setOpenModal, handleModalClose } = useLocaleContext();
	const threshold = getThreshold(locale, siteSettings) ?? 0;
	const { copy, currencyCode } = LOCALE_DICT[locale];
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const formattedThreshold = formatCurrency({ amount: threshold, currencyCode, locale }, showCurr);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Modal open={openModal} onOpenChange={setOpenModal}>
			<Modal.Content className={styles.container} customAnimation={slideUp} includeCloseButton={false} removePadding>
				<Modal.Close className={styles['close-button']}>
					<Close label={translations.close} onClick={() => setOpenModal(false)} />
				</Modal.Close>
				<Flex align='start' className={styles.copy}>
					<Title style={{ fontSize: '2.4rem', lineHeight: '2.4rem' }}>{translations.welcomeToPairEyewear}</Title>
					<div>
						<Caption>{translations.youShopIn(copy)}</Caption>
						<Paragraph>
							<ul>
								<li>{translations.shopInLocal}</li>
								<li>{translations.allDutiesAndTaxes}</li>
								<li>{translations.freeShippingOnOver(formattedThreshold)}</li>
							</ul>
						</Paragraph>
					</div>
					<Flex className={styles['footer-buttons']} fullWidth>
						<Modal.Close>
							<Button
								label={translations.continueShopping}
								size='medium'
								fullWidth
								extraClasses={styles['continue-button']}
								onClick={() => handleModalClose(locale)}
							/>
						</Modal.Close>
					</Flex>
				</Flex>
			</Modal.Content>
		</Modal>
	);
};

export default InternationalWelcomeModal;
