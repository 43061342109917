import { ListProps } from '@components';

export const BUNDLE_KEY_QUERY = 'bundleKey';
export const CART_SHIPPING_CANADA = 25;
export const CART_SHIPPING_US = 6;
export const CART_COOKIE_EXPIRE = 10; // 10 days
export const CART_COOKIE_ID = 'shopify_cartId';
export const CART_LOCALSTORAGE_ID = 'pair_shopify_cartId'; // Added as backup to Cookie retrieval
export const CART_LINE_ATTRIBUTE_KEYS = {
	BUNDLE_KEY: '_bundle_key',
	COLLECTION_PATH: '_collectionPath',
	CUSTOMER_JOURNEY: '_customerJourney',
	CUSTOMER_TYPE: '_customerType',
	FRAME: '_Frame',
	LENS_COLOR: '_lensColor',
	LENS_IMAGE_URL: '_lensImageurl',
	PRESCRIPTION_TYPE: '_prescriptionType',
	READER_STRENGTH: '_readerStrength',
	SUBMISSION_METHOD: '_submissionMethod',
	PD_MEASUREMENT: '_pdMeasurement',
	PD_MEASUREMENT_METHOD: '_pdMeasurementMethod',
	CHECKOUT_BLOCK: '_IsCheckoutBlock',
};
export const DELETE_BASE_FRAME = {
	TITLE: 'Are you sure?',
	LABEL: 'You are about to remove',
	PROMPT: 'Doing this will also remove your lens selections. Please confirm.',
};
export const PURCHASE_CHECKLIST: ListProps = [
	{
		description: 'Includes Frame Case and Lens Cloth',
		checkmark: true,
	},
	{
		description: 'Free Standard Shipping On Orders $40+',
		checkmark: true,
	},
	{
		description: '30-Day Trial With Free Returns',
		checkmark: true,
	},
	{
		description: 'FSA and HSA Eligible or Insurance Reimbursement',
		checkmark: true,
		tooltip: 'All refunds will be issued to the original payment method — no exceptions',
	},
];

//--- Handles & SKUs ---//
export const HANDLE_PROGRESSIVE_LENSES = 'progressive-lenses';
export const HANDLE_SUNGLASS_LENSES = 'sunglass-lenses';
export const SKU_BLUE_LIGHT = 'BLUE_LIGHT';
export const SKU_PROGRESSIVE = 'PROGRESSIVE';
export const SKU_SUN_LENS = 'SUN_LENS';
export const SUN_LENS_REFLECTIVE_TEXT = 'REFLECTIVE';
