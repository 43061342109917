import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useIsWKWebView } from '@utils/check-for-wkwebview';
import { Pause, Play } from '@components';
import styles from './Video.module.scss';
import type { Video as VideoType } from '@ts/shopify-storefront-api';
type VideoProps = {
	video: VideoType;
	className?: string;
	poster?: string;
	mobile?: boolean;
	isModalOpen?: boolean;
	topRightButton?: boolean;
};

function Video({ video, className, poster, mobile, topRightButton }: VideoProps) {
	const playerRef = useRef<HTMLVideoElement>();
	const isWKWebView = useIsWKWebView();
	const [isPaused, setIsPaused] = useState(isWKWebView);
	const [playerError, setPlayerError] = useState(false);

	useEffect(() => {
		playerRef?.current &&
			playerRef.current
				.play()
				.then(() => {
					const isPlaying =
						playerRef.current.currentTime > 0 &&
						!playerRef.current.paused &&
						!playerRef.current.ended &&
						playerRef.current.readyState > playerRef.current.HAVE_CURRENT_DATA;

					if (isPlaying) {
						playerRef.current.pause();
					}

					playerRef.current.load();
				})
				.catch(e => {
					// Media was removed error is expected in resize events.
					// "setPlayerError" is used to show a fallback image on iOS low-power mode.
					if (!e.message.includes('media was removed')) {
						console.error(e);
						setPlayerError(true);
					}
				});
	}, [mobile]);

	const videoIOS = useMemo(() => {
		return video.sources.find(source => source.mimeType === 'video/mp4');
	}, [video]);
	return playerError || video.sources.length == 0 ? (
		<picture className={className}>
			<source srcSet={videoIOS?.url} type={videoIOS?.mimeType} />
			<img src={poster} alt={video?.alt} />
		</picture>
	) : (
		<>
			<video
				key={video?.sources?.[0]?.url}
				ref={playerRef}
				playsInline
				loop
				muted
				className={className}
				autoPlay={!isPaused}
				poster={poster}
				style={{ width: '100%', height: '100%', objectFit: 'cover' }}
			>
				{video.sources.map(source => (
					<source key={source.url} src={source.url} type={source.mimeType} />
				))}
			</video>
			<div
				className={cn(styles['pause-play-button'], { [styles['pause-play-button--top-right']]: topRightButton })}
				onClick={e => {
					e.stopPropagation();
					setIsPaused(!isPaused);
					if (playerRef.current) {
						if (isPaused) {
							playerRef.current.play();
						} else {
							playerRef.current.pause();
						}
					}
				}}
				aria-label='pause/play'
				data-testid='pause-btn'
			>
				{isPaused ? <Play /> : <Pause />}
			</div>
		</>
	);
}

export default Video;
