import { NormalizedCart } from '@ts/cart';
import { PAIR_EYEWEAR, getShopifyIdFromGID } from '@utils/index';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { getCustomer } from '@services/shopify';
import { getCustomerToken } from '@services/shopify/customer-token';
import { getCollectionPathFromCartLine } from '@components';
import { DataLayerProduct, DataLayerImpression, DataLayerUserProperties } from '../data-layer';

export const getUserProperties = async (): Promise<DataLayerUserProperties> => {
	let customer = null;
	const customerAccessToken = getCustomerToken();
	if (customerAccessToken) {
		try {
			customer = await getCustomer(customerAccessToken);
		} catch (error) {
			console.error('Error getting customer', error);
		}
	}
	if (!customer) return { user_consent: '', visitor_type: 'guest' };
	const { email, id, firstName, lastName, defaultAddress, tags, numberOfOrders } = customer;
	return {
		user_consent: '',
		visitor_type: 'logged_in',
		customer_address_1: defaultAddress?.address1 ?? '',
		customer_address_2: defaultAddress?.address2 ?? '',
		customer_city: defaultAddress?.city ?? '',
		customer_country: defaultAddress?.country ?? '',
		customer_email: email,
		customer_first_name: firstName,
		customer_id: id,
		customer_last_name: lastName,
		customer_order_count: `${numberOfOrders ?? 0}`,
		customer_phone: defaultAddress?.phone ?? '',
		customer_province: defaultAddress?.province ?? '',
		customer_province_code: defaultAddress?.provinceCode ?? '',
		customer_country_code: defaultAddress?.countryCode ?? '',
		customer_tags: `${tags}`,
		customer_total_spent: '0.00',
		customer_zip: defaultAddress?.zip ?? '',
	};
};

export const getVariant = ({ variant, path }: { variant: NormalizedVariant; path: string }): DataLayerProduct => {
	return {
		id: variant.sku,
		name: variant.name,
		brand: PAIR_EYEWEAR,
		price: `${variant.price.amount.toFixed(2)}`,
		category: variant.type,
		variant: variant.option,
		list: path,
		product_id: `${getShopifyIdFromGID(variant.product.id)}`,
		variant_id: `${getShopifyIdFromGID(variant.id)}`,
		compare_at_price: '0.00',
		image: `${variant.image?.url}`,
		quantity: '1',
		inventory: '1',
		position: 0,
	};
};

export const getVariants = ({ variants, path }: { variants: NormalizedVariant[]; path: string }): DataLayerProduct[] => {
	return variants.map(v => ({
		id: v.sku,
		name: v.name,
		brand: PAIR_EYEWEAR,
		price: `${v.price.amount.toFixed(2)}`,
		category: v?.product?.type,
		variant: v.option,
		list: path,
		product_id: `${getShopifyIdFromGID(v.product.id)}`,
		variant_id: `${getShopifyIdFromGID(v.id)}`,
		image: v.image?.url,
		quantity: '1',
	}));
};

export const getProducts = (cart: NormalizedCart): DataLayerProduct[] => {
	return cart.lines.map(({ variant, quantity, properties }) => ({
		id: variant.sku,
		name: variant.name,
		brand: PAIR_EYEWEAR,
		price: `${(variant.price.amount * quantity).toFixed(2)}`,
		category: variant.product.type,
		variant: variant.option,
		list: properties?._collectionPath ?? getCollectionPathFromCartLine(properties),
		product_id: `${getShopifyIdFromGID(variant.product.id)}`,
		variant_id: `${getShopifyIdFromGID(variant.id)}`,
		quantity: `${quantity}`,
		image: variant.image?.url,
	}));
};

export const getImpressionsFromCart = (cart: NormalizedCart): DataLayerImpression[] => {
	return cart.lines.map(({ variant, properties, quantity }, index) => ({
		id: variant.sku,
		name: variant.name,
		brand: PAIR_EYEWEAR,
		price: `${(variant.price.amount * quantity).toFixed(2)}`,
		category: variant.product.type,
		variant: variant.option,
		quantity: `${quantity}`,
		list: properties?._collectionPath ?? getCollectionPathFromCartLine(properties), // Not required for dl_view_cart
		product_id: `${getShopifyIdFromGID(variant.product.id)}`,
		variant_id: `${getShopifyIdFromGID(variant.id)}`,
		image: variant?.image?.url,
		position: index + 1,
	}));
};

export const getImpressionsFromHits = (hits): DataLayerImpression[] => {
	return hits.slice(0, 25).map(({ sku, id, objectID, title, variant_title, price, product_type, image }, index) => ({
		id: sku,
		name: title,
		brand: PAIR_EYEWEAR,
		price: `${price?.toFixed(2)}`,
		category: product_type,
		variant: variant_title,
		quantity: '1',
		// It takes the path where the user searched for the product
		list: window?.location?.pathname ?? '',
		product_id: `${id}`,
		variant_id: objectID,
		image: image,
		position: index + 1,
	}));
};

export const getImpressionsFromProducts = ({
	products,
	path,
}: {
	products: NormalizedProduct[];
	path: string;
}): DataLayerImpression[] => {
	return products.map(({ id, name, price, type, images, variants }, index) => ({
		id: `${id}`,
		name: name,
		brand: PAIR_EYEWEAR,
		price: `${price.amount.toFixed(2)}`,
		category: type,
		variant: variants[0].name,
		quantity: '1',
		list: path,
		product_id: `${id}`,
		variant_id: `${variants[0].id}`,
		image: images[0],
		position: index + 1,
	}));
};

export const getImpressionsFromVariants = ({
	variants,
	path,
}: {
	variants: NormalizedVariant[];
	path: string;
}): DataLayerImpression[] => {
	return variants.map(({ id, sku, name, price, type, product, image }, index) => ({
		id: `${sku}`,
		name: name,
		brand: PAIR_EYEWEAR,
		price: `${price.amount.toFixed(2)}`,
		category: type,
		variant: name,
		quantity: '1',
		list: path,
		product_id: `${getShopifyIdFromGID(product.id)}`,
		variant_id: `${id}`,
		image: image?.url,
		position: index + 1,
	}));
};
