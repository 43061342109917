/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
	getAlsoWorthCheckoutCopy,
	getBadgeTypes,
	LOCALE_CODES,
	LOCALE_DICT,
	MEDIUM_LARGE_WIDTH,
	MOBILE_MAX_WIDTH,
	PRODUCT_TYPES,
	SHOP_PAGE_URL,
	SHOPIFY_CHECKOUT_URL,
} from '@constants';
import variables from '@styles/export.module.scss';
import {
	BaseFrameBundle,
	Button,
	Caption,
	CartHeader,
	Checklist,
	Container,
	DiscountBanner,
	EmptyCart,
	Flex,
	Footer,
	FreeShippingTracker,
	Heading,
	HorizontalCard,
	LineItemCard,
	Loading,
	MembershipOptions,
	SidebarLayout,
	Spacer,
	Subtotal,
	TopFrameRecommendation,
	TypographyButton,
} from '@components';
import {
	useActiveFrame,
	useDebounce,
	useFeatureInLocale,
	useHasMounted,
	useIsMobile,
	useIsVipMembership,
	useLocalStorage,
	useTranslation,
} from '@utils/hooks';
import { useCart, useCustomer, useProductQuery } from '@services/shopify';
import { trackCartView } from '@services/analytics/trackers';
import { useSiteSettings } from '@services/contentful';
import { useCartContext } from '@context';
import fetchMultipassApi from '@services/shopify/fetch-multipass-api';
import { getCartBreakdown, getChecklist, getThreshold, getTopContainerClass, hasDuplicatedKeys } from '../cart-utils';
import styles from './Minicart.module.scss';

const getTranslatedTexts = translator => {
	return {
		loading: translator('loading-text'),
		viewCart: translator('view-cart'),
		keepShopping: translator('keep-shopping'),
		checkout: translator('checkout'),
		myTopFrames: translator('my-top-frames'),
		accessories: translator('accessories'),
		myBundle: translator('my-bundle'),
		edit: translator('edit'),
		remove: translator('remove'),
		mySubscription: translator('my-subscription'),
		otherItems: translator('other-items'),
		completeYourOrderMessage: translator('complete-your-order-message'),
		withYourPurchase: translator('with-your-purchase'),
	};
};

const Minicart = ({ forceMobileStyle = false, footer = null, isMinicart = false }) => {
	const [checkoutText, setCheckoutText] = useState('Checkout');
	const [activeFrame] = useActiveFrame();
	const { locale, push } = useRouter();
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const { data: cart, isLoading: isCartLoading } = useCart();
	const { applyDiscountMembership, isMembershipActivated } = useIsVipMembership();
	const { data: siteSettings, isLoading: isSiteSettingsloading } = useSiteSettings();
	const { isCartMutating, isUploadingRx, handleCartRemove, applySubscriptionDiscount } = useCartContext();
	const isUS = country === 'US';
	const isUpsellsInCheckout = useFeatureIsOn('is-upsells-in-checkout') && isUS;
	const isCartPDTest = useFeatureIsOn('is-cart-pd-test');
	const { data: customer } = useCustomer();
	const { data: pairCare, isLoading: isPairCareLoading } = useProductQuery(
		'pair-care',
		{},
		{ queryRefreshVars: [locale], locale }
	);
	const { data: topFrameCase } = useProductQuery('top-frame-case', { country, language }, { queryRefreshVars: [locale] });
	const { data: cleaningKit } = useProductQuery('cleaning-kit', { country, language }, { queryRefreshVars: [locale] });
	const { data: accessoriesBundle } = useProductQuery(
		'accessories-bundle',
		{ country, language },
		{ queryRefreshVars: [locale] }
	);

	const anyAsyncLoading = isSiteSettingsloading || isCartLoading || isPairCareLoading;
	const [hasUsedPDInCart, setHasUsedPDInCart] = useLocalStorage('hasUsedPDInCart', null);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const isTablet = !useIsMobile({ maxWidth: MOBILE_MAX_WIDTH }) && isMobile;
	const isMounted = useHasMounted();
	const debouncedTrackCartView = useDebounce(trackCartView);
	const threshold = getThreshold(locale, siteSettings);
	const isTrackingFreeShipping = !!threshold && !isSiteSettingsloading;
	const isKeepShoppingTestActive = useFeatureIsOn('is-cart-cta-test');
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const isBfroDiscount = useFeatureIsOn('is-bfro-discount');
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');
	const accessories = [accessoriesBundle, topFrameCase, cleaningKit].filter(
		accessory => !!accessory && !cart?.lines.some(line => line.variant.product.name === accessory.name)
	);
	const router = useRouter();
	const { topFrames, bundles, others, customBundles, subscriptions } = getCartBreakdown(cart, isV2Subscription);
	const hasBundleOrBaseTops = bundles?.some(bundle => !!bundle?.tops || !!bundle?.base) && !isUpsellsInCheckout;
	const baseBundles = bundles.filter(bundle => !!bundle?.base);
	const isCheckoutDisabled = isCartMutating || isUploadingRx;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const ALSO_WORTH_CHECKOUT_COPY = getAlsoWorthCheckoutCopy(locale);
	const BADGE_TYPES = getBadgeTypes(locale);

	const onClickCheckout = async () => {
		setCheckoutText(translations.loading);
		if (hasDuplicatedKeys(cart)) {
			console.error(`Cart with id ${cart.id} has duplicated keys`, cart);
		}

		if (!customer) {
			push(cart?.checkoutUrl.replace(SHOPIFY_CHECKOUT_URL, SHOP_PAGE_URL));
			return;
		}

		await fetchMultipassApi(cart, customer, push);
	};
	const ButtonContainer = ({ children }: { children: React.ReactNode }) =>
		isCartUsability && (isTablet || !isMobile) ? (
			<Flex align='center' gap={3}>
				{children}
			</Flex>
		) : (
			<>{children}</>
		);
	const cta = (
		<>
			<Subtotal
				subtotal={!applyDiscountMembership ? cart?.subtotal : cart?.subtotalWithDiscount}
				applyDiscountMembership={cart?.lines.length > 0 && applyDiscountMembership}
			/>
			<ButtonContainer>
				{isCartUsability && isMinicart && (isTablet || !isMobile) && (
					<Button color='white' fullWidth label={translations.viewCart} href='/cart' data-cart-view-cart='view-cart' />
				)}
				<Button
					onClick={onClickCheckout}
					data-checkout
					fullWidth
					label={checkoutText}
					disabled={!cart?.lines.length || isCheckoutDisabled}
					style={{
						pointerEvents: isCheckoutDisabled ? 'none' : 'auto',
						cursor: isCheckoutDisabled ? 'not-allowed' : 'pointer',
						height: '4.8rem',
					}}
				/>
			</ButtonContainer>
			{isKeepShoppingTestActive && !isMinicart && (
				<>
					<Spacer size={'0.8rem'} />
					<Button href='/' label={translations.keepShopping} color='white' fullWidth data-keep-shopping />
				</>
			)}
		</>
	);

	const removeAllCustomBundle = (key: string) => {
		const ids = customBundles[key].map(({ top }) => top.id);
		handleCartRemove(ids);
	};

	useEffect(() => {
		if (cart) debouncedTrackCartView(cart);
	}, []);

	useEffect(() => {
		if (!anyAsyncLoading && !isCartMutating && !!cart && !cart.lines.length && hasUsedPDInCart) {
			const hasBaseFrames = cart.lines.some(line => line.variant.type.includes(PRODUCT_TYPES.BASE_FRAME));
			if (!hasBaseFrames) {
				setHasUsedPDInCart(null);
			}
		}
	}, [cart, anyAsyncLoading, isCartMutating, hasUsedPDInCart]);

	useEffect(() => {
		if (checkoutText === translations.loading) {
			setTimeout(() => {
				setCheckoutText(translations.checkout);
			}, 1500);
		}
	}, [checkoutText]);

	if (anyAsyncLoading || !isMounted) return <Loading />;

	const CartHeaderComponents = () => {
		const Subtotal = (
			<CartHeader
				itemCount={cart?.totalQuantity ?? 0}
				subtotal={!applyDiscountMembership ? cart?.subtotal : cart?.subtotalWithDiscount}
			/>
		);
		const Discount = isBfroDiscount && <DiscountBanner margin='mobile' />;
		const Shipping = isTrackingFreeShipping && (
			<FreeShippingTracker
				locale={locale}
				freeShippingThreshold={getThreshold(locale, siteSettings)}
				cartTotal={!applyDiscountMembership ? cart?.subtotal : cart?.subtotalWithDiscount}
			/>
		);

		return [Subtotal, Discount, Shipping];
	};

	const showMembershipOptions = !customer?.isMembershipVip && isUS && cart?.lines.length > 0 && isMembershipActivated;

	return (
		<>
			{forceMobileStyle && <SidebarLayout.Header inDrawer={forceMobileStyle} />}
			<SidebarLayout.Root
				forceMobileStyle={forceMobileStyle}
				cartStyle
				isMinicart={isMinicart}
				isCartUsability={isCartUsability}
			>
				<SidebarLayout.Content
					className={isCartPDTest ? styles.content : undefined}
					forceMobileStyle={forceMobileStyle}
					isMinicart={isMinicart}
					isMobile={isMobile}
					cartStyle
					isCartUsability={isCartUsability}
				>
					<Flex
						className={forceMobileStyle ? styles.containerInDrawer : styles.container}
						column
						center
						fullWidth
						gap={4}
					>
						<CartHeaderComponents />
						{showMembershipOptions && (
							<MembershipOptions subtotal={cart?.subtotal} subtotalWithDiscount={cart?.subtotalWithDiscount} />
						)}
						{!cart?.lines.length && <EmptyCart />}
						{baseBundles.map(bundle =>
							bundle?.base ? (
								<>
									<BaseFrameBundle
										isLoading={anyAsyncLoading}
										pairCare={pairCare}
										key={bundle.key}
										bundle={bundle}
										cartId={cart?.id}
										forceMobileStyle={forceMobileStyle}
										tags={applySubscriptionDiscount ? [BADGE_TYPES.MEMBERS_DISCOUNT] : []}
										isMinicart={isMinicart}
									/>
								</>
							) : null
						)}
						{topFrames.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)}>
								<Caption className={isCartUsability ? styles.topFrameTitle : styles.bundleTitle}>
									{translations.myTopFrames}
								</Caption>
								{topFrames.map(({ optimistic, top }) => (
									<LineItemCard
										isMinicart={isMinicart}
										key={`line-${top?.id ?? nanoid()}`}
										data={top}
										dataSource={'shopify'}
										optimistic={optimistic}
										showTags
										customTags={applySubscriptionDiscount ? [BADGE_TYPES.MEMBERS_DISCOUNT] : []}
										dataTags={{
											button: {},
											zoom: {
												'data-tops-zoom': 'cart',
											},
										}}
										forceDefault={false}
									/>
								))}
							</Flex>
						)}
						{isCartUsability && others.length > 0 && (
							<Flex
								column
								gap={3}
								maxWidth
								className={getTopContainerClass(forceMobileStyle, styles)}
								data-testid='accessories-items'
							>
								<Heading tag='h6' className={styles.cartUsabilityAccessories}>
									{translations.accessories}
								</Heading>
								{others.map(({ item, optimistic }) => (
									<LineItemCard
										key={`line-${item?.id ?? nanoid()}`}
										data={item}
										dataSource={'shopify'}
										optimistic={optimistic}
										large={isCartUsability}
										isMinicart={isMinicart}
									/>
								))}
							</Flex>
						)}
						{customBundles &&
							Object.keys(customBundles).map(key => {
								return (
									<Flex
										key={key}
										column
										gap={4}
										maxWidth
										className={getTopContainerClass(forceMobileStyle, styles)}
									>
										<Flex justify='between'>
											<Caption className={styles.bundleTitle}>{translations.myBundle}</Caption>
											<Flex justify='end' gap={4}>
												<Button
													size='small'
													linkStyle
													onClick={() => {
														router.push({
															pathname: '/build-your-own-bundle',
															query: {
																edit: true,
																bundle: key,
															},
														});
													}}
													dataTags={{ 'data-cart-edit': 'BYOB' }}
												>
													{translations.edit}
												</Button>
												<TypographyButton small style={{ color: variables.gray4 }}>
													|
												</TypographyButton>
												<Button
													size='small'
													linkStyle
													onClick={() => removeAllCustomBundle(key)}
													dataTags={{
														'data-remove': `BYOB-${customBundles[key].length}Top`,
														'data-type-remove': 'BYOB',
													}}
												>
													{translations.remove}
												</Button>
											</Flex>
										</Flex>
										{customBundles[key].map(({ optimistic, top }) => {
											return (
												<LineItemCard
													key={`line-${top?.id ?? nanoid()}`}
													data={top}
													dataSource={'shopify'}
													noInteraction
													forceDefault
													optimistic={optimistic}
													dataTags={{
														button: {},
														zoom: {
															'data-tops-zoom': 'cart',
														},
													}}
												/>
											);
										})}
									</Flex>
								);
							})}
						{isCartUsability && subscriptions.length > 0 && (
							<Flex column gap={4} maxWidth className={getTopContainerClass(forceMobileStyle, styles)}>
								<Caption className={styles.bundleTitle}>{translations.mySubscription}</Caption>
								{subscriptions.map(({ optimistic, top }) => (
									<LineItemCard
										key={`line-${top?.id ?? nanoid()}`}
										data={top}
										dataSource={'shopify'}
										optimistic={optimistic}
										forceDefault
										dataTags={{
											button: {},
											zoom: {
												'data-tops-zoom': 'cart',
											},
										}}
									/>
								))}
							</Flex>
						)}
						{!isCartUsability && others.length > 0 && (
							<Flex
								column
								gap={4}
								maxWidth
								className={getTopContainerClass(forceMobileStyle, styles)}
								data-testid='other-items'
							>
								<Caption className={styles.bundleTitle}>{translations.otherItems}</Caption>
								{others.map(({ item, optimistic }) => {
									return (
										<LineItemCard
											key={`line-${item?.id ?? nanoid()}`}
											data={item}
											dataSource={'shopify'}
											optimistic={optimistic}
										/>
									);
								})}
							</Flex>
						)}
						{hasBundleOrBaseTops && !isMobile && !isMinicart && (
							<TopFrameRecommendation activeFrame={activeFrame} bundles={bundles} isMinicart={forceMobileStyle} />
						)}
					</Flex>
					{!!footer && !isMobile && (
						<Flex fullWidth className={styles['footer']}>
							<Footer footer={footer} page='cart' />
						</Flex>
					)}
				</SidebarLayout.Content>
				{hasBundleOrBaseTops && (isMobile || isMinicart) && (
					<TopFrameRecommendation activeFrame={activeFrame} bundles={bundles} isMinicart={forceMobileStyle} />
				)}
				<SidebarLayout.Sidebar
					isMinicart={isMinicart}
					isCartUsability={isCartUsability}
					forceMobileStyle={forceMobileStyle}
					isMobile={isMobile}
					cartStyle
				>
					{!isUpsellsInCheckout && accessories.length > 0 && (
						<Container
							backgroundColor='white'
							pad={4}
							borderRadius={4}
							className={styles['upsellContainer']}
							data-testid='accessories-upsell'
						>
							<Caption
								className={
									cart?.lines.length || forceMobileStyle ? styles['upsellTitle'] : styles['upsellTitle--empty']
								}
							>
								{translations.completeYourOrderMessage}
							</Caption>
							<Flex column gap={3} align='center'>
								{accessories.map(u => {
									if (!u || cart?.lines.some(line => line.variant.product.name === u.name)) return null;

									return (
										<HorizontalCard
											key={u?.handle}
											product={u}
											variant={u?.variants[0]}
											alreadyInCart={cart?.lines.some(line => line.variant.product.name === u.name)}
											openMinicartOnPurchase={false}
											showTags={false}
											description={ALSO_WORTH_CHECKOUT_COPY[u?.handle] ?? u?.description}
											dataTags={{
												button: u?.handle ? { 'data-accessory-added': u?.handle } : {},
												zoom: {},
												favorite: {},
											}}
											isMinicart
										/>
									);
								})}
							</Flex>
						</Container>
					)}
					{!!cart?.lines.length && (
						<Container className={styles['checklistPadded']} backgroundColor='white'>
							<Checklist
								title={translations.withYourPurchase}
								backgroundColor='transparent'
								list={getChecklist(cart, locale, siteSettings)}
							/>
						</Container>
					)}
					{!forceMobileStyle && !isMobile && <SidebarLayout.CTA isMinicart={isMinicart}>{cta}</SidebarLayout.CTA>}
				</SidebarLayout.Sidebar>
			</SidebarLayout.Root>
			{!!footer && isMobile && (
				<Flex fullWidth className={styles['footer']}>
					<Footer footer={footer} page='cart' />
				</Flex>
			)}
			{(forceMobileStyle || isMobile) && (
				<SidebarLayout.CTA forceMobileStyle isMinicart={isMinicart}>
					{cta}
				</SidebarLayout.CTA>
			)}
		</>
	);
};

export default Minicart;
