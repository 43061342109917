/* eslint-disable max-lines */
import { forwardRef, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import aa from 'search-insights';
import { useRouter } from 'next/router';
import { Image as ShopifyImage } from '@ts/shopify-storefront-api';
import {
	BASE_FRAME_NAMES,
	DEFAULT_CATEGORIES,
	DEFAULT_DEMOS,
	FRAME_COLOR_HANDLES,
	FRAME_COLORS,
	LENS_COLORS,
	LOCALE_CODES,
	PRODUCT_TYPES,
} from '@constants';
import {
	ALGOLIA_SEARCH_INDEXES,
	EMPTY_PRODUCT,
	generateLifestyleImages,
	generateVariantUrl,
	getBadgeTypes,
	getShopifyIdFromGID,
	isFalsy,
	LOCALE_DICT,
	useFeatureInLocale,
	useIsDiscountEligible,
	useIsProductVariantSoldOut,
	useIsVipMembership,
	useSubscription,
	useTranslation,
} from '@utils/index';
import { trackSelectedProduct } from '@services/analytics/trackers';
import { useCustomer } from '@services/shopify';
import { useBaseFrame, useLozengeData } from '@utils/hooks';
import { CardProps } from '@ts/components';
import { useTailoredExperience } from '@services/contentful';
import { useCartContext } from '@context';
import { CarouselModal } from '@components';
import { getProductDataTag } from '@utils/dataTags';
import RichMedia from '../RichMedia';
import CardContent from '../CardContent';
import ButtonGroup from '../ButtonGroup';
import Tags from '../Tags';
import styles from './Card.module.scss';
import Copy from './Copy';
import VariantController from './VariantController';

// TODO
// - add "useProduct" hook, compare rendering
// - add "primary action"
// - test in "Search" story

const getTranslatedTexts = translator => {
	return {
		loading: translator('loading'),
	};
};

export type CardPropsType = Omit<CardProps & { large?: boolean }, 'children'>;
const Card = forwardRef<HTMLDivElement, CardPropsType>(
	(
		{
			aspectRatio = '4/3',
			alreadyInCart = false,
			buttonGroupType = 'side-by-side',
			compact = false,
			description,
			hoverable = true,
			noBorder = false,
			noImage = false,
			quantity = 1,
			containerType = 'vertical',
			contentType = 'vertical',
			isError = false,
			isFetching = false,
			isLoading = false,
			openMinicartOnPurchase = true,
			parentCollectionHandle,
			product: inheritedProduct = EMPTY_PRODUCT,
			primaryAction = 'cart',
			primaryActionData,
			secondaryAction = 'none',
			showAction = false,
			secondaryActionData,
			showDescription = false,
			customTags,
			showCollectionLozenge = false,
			showTags = false,
			showVariantControls = false,
			showLensController = false,
			supplemental,
			variant: inheritedVariant,
			state: inheritedState = null,
			dispatch: inheritedDispatch = null,
			productRemoved = false,
			label = 'View',
			className,
			onMouseEnter,
			isMobile,
			ImgClickHandler,
			bundleKey,
			searchPosition,
			dataTags = {
				button: {},
				zoom: {},
				favorite: {},
			},
			pageType,
			showCarouselModal = false,
			useExactPrice = false,
			showCustomTagsOnly,
			positionTag,
			isMinicart = false,
			large = false,
			forceDefault = true,
			initialColor = undefined,
			initialLens,
			customImgHover,
			applyAutomaticDiscount = true,
			hoverCallback,
			customHoverSrc,
			bulletVariant = 'default',
			alignTitle = false,
			enableLifeStyleGallery = false,
			...rest
		}: CardPropsType,
		ref
	) => {
		const { locale } = useRouter();
		const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
		const { translator } = useTranslation();
		const translations = getTranslatedTexts(translator);

		const [baseFrameLifeStyleImg, setBaseFrameLifeStyleImg] = useState(null);
		const classes = cn(styles.container, className, {
			[styles.containerVertical]: containerType === 'vertical',
			[styles.containerHorizontal]: containerType === 'horizontal',
			[styles.containerLineItem]: containerType === 'line-item',
			[styles.containerBuildFlow]: containerType === 'build-flow',
			[styles.containerHoverable]: hoverable,
			[styles.containerNoBorder]: noBorder,
			[styles.containerProductRemoved]: productRemoved,
			[styles.containerMobileWithBigImage]:
				containerType === 'line-item' && isCartUsability && (isMobile || isMinicart) && !forceDefault,
			[styles.containerLarge]: large,
		});
		const BADGE_TYPES = getBadgeTypes(locale);

		const { data: customer } = useCustomer();
		const { searchQueryId } = useCartContext();
		const countryCode = LOCALE_DICT[locale].countryCode;
		const [ownedState, ownedDispatch] = useBaseFrame(
			inheritedProduct,
			inheritedVariant,
			showLensController,
			initialLens as LENS_COLORS,
			initialColor?.replaceAll('%20', ' ') as FRAME_COLORS
		);
		const state = inheritedState ?? ownedState;
		const { product = inheritedProduct, variant = inheritedVariant } = state ?? {};

		if (inheritedVariant && variant.option === inheritedVariant.option && inheritedVariant?.metafields?.shipmentInfo) {
			variant.metafields = inheritedVariant.metafields;
		}
		const lozengeData = useLozengeData(product);
		const dispatch = inheritedDispatch ?? ownedDispatch;

		//--- UTM Param ---//
		const router = useRouter();
		const { data: tailoredExperience } = useTailoredExperience();
		const utmParam = tailoredExperience ? `&utm_term=${tailoredExperience?.utmTerm}` : '';

		const isSunglassesPDP = router.pathname === '/sunglasses/[demo]/[handle]';
		const typePDP = router.query?.demo as string;
		const topLifestyleImages = product?.normalizedMetafields?.lifestyleImage;
		const isTopLifeStyleImgAvailable = router.route.includes('top-frames') && topLifestyleImages;
		const isBaseFrame = product?.type === PRODUCT_TYPES.BASE_FRAME || product?.type === PRODUCT_TYPES.BASE_FRAME_SR;

		const collectionHandle = parentCollectionHandle ?? lozengeData?.lozengeHandle ?? typePDP;
		const linkToProduct = generateVariantUrl({
			variant: !!initialColor ? variant : (inheritedVariant ?? variant),
			option: Boolean(variant.option) ? variant.option : (inheritedVariant?.name ?? variant?.name),
			collection: collectionHandle, // lozenge data should only be used in Algolia contexts (or where a collection isn't in scope) -- Recs, Search, etc.
			sunLensColor: isSunglassesPDP ? LENS_COLORS.BLACK : state?.lens,
			isBlueLight: router.route.includes('blue-light'),
			isSubscription: !!router.query?.subscriptionPath,
			isMembershipTops: !!router.asPath?.includes('membership-tops'),
		});

		const collectionPath = linkToProduct?.split(product.handle)[0];
		const hasCategory = DEFAULT_CATEGORIES.some(category => router.asPath.includes(category));
		const { checkIfSoldOut } = useIsProductVariantSoldOut();
		const isSoldOut = checkIfSoldOut(product.handle, variant.option);

		const lifestyleImages = useMemo(() => {
			if (typePDP && hasCategory && isBaseFrame) {
				return generateLifestyleImages({
					demo: router.query?.demo as (typeof DEFAULT_DEMOS)[number],
					category: router.route.includes('sunglasses') ? 'sunglasses' : 'eyeglasses',
					name: product.name as `The ${(typeof BASE_FRAME_NAMES)[number]}`,
				});
			}
			return null;
		}, [typePDP, hasCategory, isBaseFrame, product.name]);

		useEffect(() => {
			if (lifestyleImages && lifestyleImages.carousel[0]) {
				const carouselUrl = lifestyleImages.carousel[0].url;

				const frameColorHandle = Object.entries(FRAME_COLORS).find(([, value]) => value === state?.frame)?.[0];
				const handleValue = FRAME_COLOR_HANDLES[frameColorHandle];
				const modifiedUrl = carouselUrl.replace('.png', `-${handleValue}.png`);

				const checkImage = (url: string): Promise<boolean> =>
					new Promise(resolve => {
						const img = new Image();
						img.onload = () => resolve(true);
						img.onerror = () => resolve(false);
						img.src = url;
					});

				const updateImages = async () => {
					const modifiedUrlExists = await checkImage(modifiedUrl);

					if (modifiedUrlExists) {
						setBaseFrameLifeStyleImg({
							...lifestyleImages,
							carousel: { ...lifestyleImages.carousel[0], url: modifiedUrl },
							sidebar: lifestyleImages.sidebar[0],
						});
					} else {
						const carouselUrlExists = await checkImage(carouselUrl);
						if (carouselUrlExists) {
							setBaseFrameLifeStyleImg({
								...lifestyleImages,
								carousel: { ...lifestyleImages.carousel[0], url: carouselUrl },
								sidebar: lifestyleImages.sidebar[0],
							});
						} else {
							setBaseFrameLifeStyleImg(null);
						}
					}
				};

				updateImages();
			}
		}, [lifestyleImages, state?.frame, state?.product.name]);

		// Could this live in trackers.ts ?
		const productClickEvent = () => {
			// Algolia
			aa('sendEvents', [
				{
					eventType: 'click',
					eventName: 'Product Clicked',
					index: ALGOLIA_SEARCH_INDEXES[countryCode],
					// eslint-disable-next-line max-len
					userToken: `${customer ? getShopifyIdFromGID(customer.id) : `guest-user-${Math.floor(Math.random() * 1000000)}`}`,
					objectIDs: [`${getShopifyIdFromGID(variant.product.id)}`, `${getShopifyIdFromGID(variant.id)}`],
					...(searchPosition &&
						searchQueryId && { queryID: searchQueryId, positions: [searchPosition, searchPosition] }),
				},
			]);

			// Elevar
			trackSelectedProduct({
				variant,
				path: collectionPath,
			});
		};
		const { isSubscriptionActivated } = useSubscription();
		const { applyDiscountMembership } = useIsVipMembership(product.type);

		const isSubscriptionDiscountEligible = useIsDiscountEligible({
			compareAtPrice: variant.compareAtPrice?.amount,
			type: product.type,
			mandatoryDiscount: containerType === 'build-flow' && isSubscriptionActivated,
		});
		const customTagsWithSubscription = useMemo(() => {
			const newCustomTags = [...(customTags ?? [])];
			if (isSubscriptionDiscountEligible) {
				newCustomTags.push(BADGE_TYPES.MEMBERS_DISCOUNT);
			}
			if (applyDiscountMembership) {
				newCustomTags.push(BADGE_TYPES.VIP_DISCOUNT);
			}
			return newCustomTags;
		}, [customTags, isSubscriptionDiscountEligible, applyDiscountMembership, product.type]);

		if (isError) return null;
		if (isFetching || isLoading) return <div>{translations.loading}</div>;
		if (window.location.toString().includes('favorites') && isSoldOut) return null;

		const image = state?.image ?? variant.image;

		if (variant.tags && variant.tags.length > 0) {
			product.tags = variant.tags;
		}

		// Focused on parity for now, but Possible to just have one? Useful to apply this to other product types?
		const containerDataTag = getProductDataTag(product.type, product.name);

		const isEmptyVariant = isFalsy(variant.handle);
		const Card = (
			<div
				data-testid='product-card'
				ref={ref}
				{...containerDataTag}
				className={classes}
				onMouseEnter={onMouseEnter}
				{...rest}
			>
				{!noImage && (
					<RichMedia
						containerType={!large ? containerType : 'large'}
						isMinicart={isMinicart}
						href={!ImgClickHandler && !isEmptyVariant && `${linkToProduct}${utmParam}`}
						src={image?.url}
						title={product.name}
						aspectRatio={aspectRatio}
						variant={variant}
						ImgClickHandler={showCarouselModal ? undefined : ImgClickHandler || productClickEvent}
						pageType={pageType}
						isModalPresent={showCarouselModal}
						hoverSrc={
							!customHoverSrc
								? hoverable
									? isTopLifeStyleImgAvailable
										? (topLifestyleImages as ShopifyImage).url
										: (baseFrameLifeStyleImg?.carousel?.url ?? '')
									: ''
								: customHoverSrc
						}
						forceDefault={forceDefault}
						secondaryAction={secondaryAction}
						dispatch={dispatch}
						product={product}
						customHover={customImgHover}
						hoverCallback={hoverable && hoverCallback ? hoverCallback : undefined}
						bulletVariant={bulletVariant}
					/>
				)}
				<CardContent
					containerType={containerType}
					type={contentType}
					compact={compact}
					hoverable={hoverable}
					noBorder={noBorder}
					showTags={showTags && customTagsWithSubscription?.length > 0}
					variant={variant}
					isCartUsability={isCartUsability}
					large={large}
					isMinicart={isMinicart}
				>
					{showTags && (
						<Tags
							containerType={containerType}
							compact={compact}
							product={product}
							lozengeData={lozengeData}
							customTags={customTagsWithSubscription}
							showCollectionLozenge={showCollectionLozenge}
							showCustomTagsOnly={showCustomTagsOnly}
							position={positionTag}
						/>
					)}
					<Copy
						quantity={quantity}
						description={description}
						compact={compact}
						product={product}
						showAction={showAction}
						showDescription={showDescription}
						state={state}
						supplementalCopy={supplemental}
						variant={variant}
						containerType={containerType}
						useExactPrice={useExactPrice}
						alignTitle={alignTitle}
					/>
					{showVariantControls && (
						<VariantController
							dispatch={ownedDispatch}
							product={product}
							showLensController={showLensController}
							state={state}
						/>
					)}
					<ButtonGroup
						primaryActionData={primaryActionData}
						secondaryActionData={secondaryActionData}
						buttonGroupType={buttonGroupType}
						compact={compact}
						dispatch={dispatch}
						primaryAction={primaryAction}
						product={product}
						secondaryAction={secondaryAction}
						variant={variant}
						productRemoved={productRemoved}
						linkToProduct={linkToProduct}
						isMobile={isMobile}
						parentCollectionHandle={collectionHandle}
						productClickEvent={productClickEvent}
						alreadyInCart={alreadyInCart}
						openMinicartOnPurchase={openMinicartOnPurchase}
						bundleKey={bundleKey}
						label={label}
						dataTags={dataTags}
						isMinicart={isMinicart}
						applyAutomaticDiscount={applyAutomaticDiscount}
					/>
				</CardContent>
			</div>
		);

		return showCarouselModal ? (
			<CarouselModal variant={variant} type={product.type} enableLifeStyle={enableLifeStyleGallery} bulletVariant='gray'>
				{Card}
			</CarouselModal>
		) : (
			Card
		);
	}
);

Card.displayName = 'Card';

export default Card;
